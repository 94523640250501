export const type = 'line'

export const options = {
  height: 100,
  title: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      stacked: false,
      scaleLabel: {
        display: true,
        labelString: 'Energy (kBtu/Person)'
      },
      ticks: {
        min: 0,
        max: 100000,
        stepSize: 10000,
        callback: value => value.toLocaleString()
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Year'
      },
      ticks: {
        fontSize: 10
      }
    }]
  },
  animation: {
    duration: 750
  },
  legend: {
    position: 'bottom',
    fullWidth: true,
    labels: {
      padding: 20
    }
  },
  tooltips: {
    enabled: true,
    mode: 'point',
    backgroundColor: 'rgba(252, 252, 252, 1)',
    bodyFontColor: 'rgba(102, 102, 102, 1)',
    cornerRadius: 2,
    xPadding: 10,
    yPadding: 12,
    caretSize: 10,
    callbacks: {
      title: () => null,
      label: t => t.yLabel.toLocaleString() + ' kBtu/Person',
      afterLabel: (t, d) => (typeof footnotes[d.datasets[t.datasetIndex].label] !== 'undefined' && typeof footnotes[d.datasets[t.datasetIndex].label][t.xLabel] === 'string')
        ? footnotes[d.datasets[t.datasetIndex].label][t.xLabel].replace(/\n\s+/g, '\n')
        : null
    }
  },
  // Note: set this on the global variable
  hover: {
    mode: 'single'
  }
}

export const data = {
  labels: [
    '2000',
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018'
  ],
  datasets: [
    {
      label: 'Total Energy',
      lineTension: 0.1,
      backgroundColor: 'rgba(0, 124, 146, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(0, 124, 146, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(0, 124, 146, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: [5, 5, 5, 5, 5, 5, 5, 5, 5, 3, 5, 5, 5, 5, 5, 3, 3, 5, 5],
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(0, 124, 146, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: [1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 1, 1, 1, 1, 1, 5, 5, 1, 1],
      pointHitRadius: 10,
      data: [82800, 84600, 83600, 87300, 90300, 86400, 84500, 82700, 83000, 78800, 78800, 77400, 75300, 74300, 72800, 59100, 56900, 56900, 54600]
    },
    {
      label: 'Electricity',
      lineTension: 0.1,
      backgroundColor: 'rgba(234, 172, 0, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(234, 171, 0, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(234, 171, 0, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(234, 171, 0, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [23300, 23100, 22600, 23400, 23900, 23400, 23800, 23800, 23400, 22800, 23600, 23100, 22900, 22600, 22300, 21400, 20800, 19500, 19200]
    },
    {
      label: 'Heating',
      lineTension: 0.1,
      backgroundColor: 'rgba(140, 21, 21, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(140, 21, 21, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(140, 21, 21, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(140, 21, 21, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      pointHitRadius: 10,
      data: [37100, 39200, 38600, 39200, 39400, 39000, 37600, 36100, 36300, 33200, 33900, 32700, 31100, 30200, 27800, 16200, 15700, 16600, 16900]
    },
    {
      label: 'Chilled Water',
      lineTension: 0.1,
      backgroundColor: 'rgba(116, 218, 217, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(116, 218, 217, 1.00)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(116, 218, 217, 1.00)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(116, 218, 217, 1.00)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [22400, 22300, 22500, 24700, 27000, 24000, 23100, 22700, 23300, 22700, 21200, 21600, 21200, 21500, 22700, 21600, 20500, 20800, 18500]
    }
  ]
}

const footnotes = {
  'Total Energy': {
    // First line should be blank, and
    // other lines should wrap here →|
    2009: `–––––––––––––––––––––––––––––––––
    The introduction of energy-saving
    programs targeting large- and
    small-scale retrofits, along with
    new construction standards,
    contributed to this decrease in
    energy use in 2009.`,
    2015: `–––––––––––––––––––––––––––––––––
    Efficiency increases in Stanford's
    energy system as a result of SESI
    allowed for a significant reduction
    in the amount of thermal energy
    produced in 2015.`,
    2016: `–––––––––––––––––––––––––––––––––
    Efficiency increases in Stanford's
    energy system as a result of SESI
    allowed for a significant reduction
    in the amount of thermal energy
    produced in 2016.`
  }
}
