export const type = 'bar'

export const options = {
  title: {
    display: false
  },
  hover: {
    mode: 'single'
  },
  tooltips: {
    enabled: true,
    mode: 'single',
    displayColors: false,
    backgroundColor: 'rgba(252, 252, 252, 1)',
    bodyFontColor: 'rgba(102, 102, 102, 1)',
    cornerRadius: 2,
    xPadding: 10,
    yPadding: 12,
    caretSize: 10,
    callbacks: {
      title: () => null,
      label: (t) => t.yLabel.toLocaleString() + ' Metric Tons of CO2',
      afterLabel: (t, d) => (typeof footnotes[d.datasets[t.datasetIndex].label] !== 'undefined' && typeof footnotes[d.datasets[t.datasetIndex].label][t.xLabel] === 'string')
        ? footnotes[d.datasets[t.datasetIndex].label][t.xLabel].replace(/\n\s+/g, '\n')
        : null
    }
  },
  legend: {
    position: 'bottom',
    fullWidth: true,
    labels: {
      fontSize: 12,
      padding: 20
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Year'
      },
      ticks: {
        fontSize: 10
      },
      categoryPercentage: 0.65
    }],
    yAxes: [{
      ticks: {
        max: 220000,
        min: 0,
        stepSize: 20000,
        callback: value => value.toLocaleString()
      },
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Metrics Tons of CO2e'
      }
    }]
  }

}

export const data = {
  labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
  datasets: [{
    label: 'Central Energy Facility — SU',
    borderColor: 'rgba(0, 124, 146, 1)',
    backgroundColor: [
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 0.35)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 0.35)',
      'rgba(0, 124, 146, 0.35)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)'
    ],
    hoverBackgroundColor: 'rgba(0, 124, 146, 0.85)',
    data: [147713, 159498, 155702, 155902, 161665, 161594, 159856, 157289, 154727, 101655, 78323, 39280, 36359]
  }, {
    label: 'Non-CEF Electricity',
    backgroundColor: [
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 0.35)',
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 0.35)',
      'rgba(182, 177, 169, 0.35)',
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 1)',
      'rgba(182, 177, 169, 1)'
    ],
    hoverBackgroundColor: 'rgba(182, 177, 169, 0.85)',
    data: [3705, 6440, 7914, 10333, 14227, 16585, 8016, 3578, 4459, 3468, 3424, 2482, 1378]
  }, {
    label: 'Non-CEF Natural Gas',
    backgroundColor: [
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 0.35)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)',
      'rgba(234, 171, 0, 1)'
    ],
    hoverBackgroundColor: 'rgba(234, 171, 0, 0.85)',
    data: [11139, 10680, 10487, 11056, 13658, 14840, 14260, 15192, 13672, 14707, 15053, 15162, 14701]
  }, {
    label: 'Stanford-Owned Vehicles',
    backgroundColor: 'rgba(116, 218, 217, 0.85)',
    hoverBackgroundColor: 'rgba(116, 218, 217, 0.65)',
    data: [2896, 3296, 3397, 3008, 3234, 4554, 4723, 5200, 5009, 3556, 3621, 2890, 1471]
  }, {
    label: 'Other',
    backgroundColor: [
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 0.35)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)',
      'rgba(140, 21, 21, 1)'
    ],
    hoverBackgroundColor: 'rgba(140, 21, 21, 1)',
    data: [2978, 2978, 3157, 2115, 7553, 5116, 5075, 5194, 5222, 5305, 4608, 5706, 3717]
  }]
}

const footnotes = {
  'Central Energy Facility — SU': {
    // First line should be blank, and
    // other lines should wrap here →|
    2007: `–––––––––––––––––––––––––––––––––
    The 2006 emissions inventory
    was not reflective of Stanford's
    actual emissions footprint due
    to significant maintenance at
    Cardinal Cogen that year. The
    2007 emissions inventory more
    fully captures Stanford's complete
    emissions profile and is thus
    commonly used as Stanford's
    baseline emissions year.`,
    2011: `–––––––––––––––––––––––––––––––––
    Stanford's peak emissions
    occurred in 2011, coinciding
    with a significant increase in the
    amount of leased space included
    in the inventory.`,
    2015: `–––––––––––––––––––––––––––––––––
    Emissions reflect three months of
    the previous cogeneration system
    and nine months of the new heat
    recovery system, which came
    online via SESI in April 2015.`,
    2016: `–––––––––––––––––––––––––––––––––
    Emissions reflect a full year of
    operation of the new Central
    Energy Facility. Stanford's
    renewable electricity portfolio was
    fully online by early 2017, but that
    emissions reduction will not be
    fully realized until the 2017
    emissions inventory.`
  },
  'Non-CEF Electricity': {
    // First line should be blank, and
    // other lines should wrap here →|
    2010: `–––––––––––––––––––––––––––––––––
    Newly available electricity
    consumption data for some School
    of Medicine buildings was captured
    for the first time in 2010.`,
    2011: `–––––––––––––––––––––––––––––––––
    Stanford's peak emissions
    occurred in 2011, coinciding
    with a significant increase in the
    amount of leased space included
    in the inventory.`,
    2012: `–––––––––––––––––––––––––––––––––
    Newly available and more precise
    utility-specific emissions factors
    were included for the first time
    in the 2012 inventory.`,
    2013: `–––––––––––––––––––––––––––––––––
    The City of Palo Alto adopted a
    carbon-neutral electricity supply
    in 2013, allowing Stanford's
    emissions to decrease.`
  },
  'Non-CEF Natural Gas': {
    2010: `–––––––––––––––––––––––––––––––––
    Stanford transitioned to a new
    reporting protocol (The Climate
    Registry) in 2010, which had
    slightly different rules for
    calculating emissions, especially
    regarding leased space, causing
    emissions in this category to
    rise.`,
    2011: `–––––––––––––––––––––––––––––––––
    Stanford's peak emissions
    occurred in 2011, coinciding
    with a significant increase in the
    amount of leased space included
    in the inventory.`
  },
  'Stanford-Owned Vehicles': {
    2011: `–––––––––––––––––––––––––––––––––
    Stanford's peak emissions
    occurred in 2011, coinciding
    with a significant increase in the
    amount of leased space included
    in the inventory.`
  },
  Other: {
    2011: `–––––––––––––––––––––––––––––––––
    Stanford's peak emissions
    occurred in 2011, coinciding
    with a significant increase in the
    amount of leased space included
    in the inventory.`,
    2015: `–––––––––––––––––––––––––––––––––
    Stanford added greenhouse gases
    other than carbon dioxide into
    its emission inventory for the
    first time in 2015, which
    primarily result from research gases
    and refrigerants, captured here.`
  }
}
