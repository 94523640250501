export const type = 'line'

export const options = {
  height: 100,
  title: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Gallons'
      },
      ticks: {
        callback: value => Math.round(value / 1000).toLocaleString() + ' M'
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Year'
      },
      ticks: {
        fontSize: 10
      }
    }]
  },
  animation: {
    duration: 750
  },
  legend: {
    position: 'bottom',
    fullWidth: true,
    labels: {
      padding: 40
    }
  },
  tooltips: {
    enabled: true,
    mode: 'single',
    backgroundColor: 'rgba(252, 252, 252, 1)',
    bodyFontColor: 'rgba(102, 102, 102, 1)',
    cornerRadius: 2,
    displayColors: false,
    xPadding: 10,
    yPadding: 12,
    caretSize: 10,
    callbacks: {
      title: () => null,
      label: (t) => t.yLabel.toLocaleString() + ' Gallons',
      afterLabel: (t, d) => (typeof footnotes[d.datasets[t.datasetIndex].label] !== 'undefined' && typeof footnotes[d.datasets[t.datasetIndex].label][t.xLabel] === 'string')
        ? footnotes[d.datasets[t.datasetIndex].label][t.xLabel].replace(/\n\s+/g, '\n')
        : null
    }
  },
  hover: {
    mode: 'single'
  }
}

export const data = {
  labels: [
    '2000',
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018'
  ],
  datasets: [
    {
      label: 'Non-potable water (for irrigation)',
      lineTension: 0.1,
      backgroundColor: 'rgba(0, 124, 146, 0.3)',
      borderWidth: 2,
      borderColor: 'rgba(0, 124, 146, 1.00)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(0, 124, 146, 1)',
      pointBackgroundColor: 'rgba(114, 198, 173, 1)',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(0, 124, 146, 0.85)',
      pointHoverBorderColor: 'rgba(241, 240, 235, 1.00)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [371119, 431426, 406634, 362740, 364159, 332149, 270526, 347163, 446777, 394942, 375157, 391342, 413575, 449440, 395688, 328350, 319193, 393067, 389323]
    },
    {
      label: 'Potable Water',
      lineTension: 0.1,
      backgroundColor: 'rgba(116, 218, 217, 0.3)',
      borderWidth: 2,
      borderColor: 'rgba(116, 218, 217, 1.00)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(116, 218, 217, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: [5, 5, 3, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 3, 3, 5, 3, 5],
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(116, 218, 217, 0.85)',
      pointHoverBorderColor: 'rgba(241, 240, 235, 1.00)',
      pointHoverBorderWidth: 2,
      pointRadius: [1, 1, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 5, 1, 5, 1],
      pointHitRadius: 10,
      data: [954678, 997183, 862795, 840132, 921125, 843081, 811757, 832417, 841782, 782323, 780810, 774723, 786742, 765961, 766159, 583150, 505949, 547686, 531392]
    }
  ]
}

const footnotes = {
  Potable: {
    // First line should be blank, and
    // other lines should wrap here →|
    2002: `–––––––––––––––––––––––––––––––––
    After the formal launch of
    Stanford's Water Efficiency program
    in FY2000–2001, Stanford quickly
    began to see reductions in both
    its potable and non-potable water
    use due to activities like
    replacements of water fixtures to
    low-flow models and other water
    efficiency retrofit projects.`,
    2014: `–––––––––––––––––––––––––––––––––
    In response to the drought that
    began in 2014, Stanford's Water
    Efficiency program worked closely
    with individual departments to
    decrease both potable and non-
    potable water use. Water use for
    irrigation was a primary focus
    of these efforts, and all campus
    divisions were able to reduce the
    amount of water used for
    irrigation during this time.`,
    2015: `–––––––––––––––––––––––––––––––––
    SESI came online in April 2015,
    allowing Stanford to significantly
    reduce its potable water use due
    to the reuse of waste heat to
    create hot water for building
    heating, rather than dispelling it
    into the atmosphere through water-
    intensive evaporative cooling
    towers.`,
    2017: `–––––––––––––––––––––––––––––––––
    Official end to four-year drought.
    A recession of conservation behavior
    is common immediately following
    drought periods.`
  }
}
