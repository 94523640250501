export const type = 'line'

export const options = {
  height: 100,
  title: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Lbs/Person'
      },
      ticks: {
        min: 0,
        max: 2400,
        stepSize: 300,
        callback: value => value.toLocaleString()
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Year'
      },
      ticks: {
        fontSize: 10
      }
    }]
  },
  animation: {
    duration: 750
  },
  legend: {
    position: 'bottom',
    fullWidth: true,
    labels: {
      padding: 40
    }
  },
  tooltips: {
    enabled: true,
    mode: 'single',
    displayColors: false,
    backgroundColor: 'rgba(252, 252, 252, 1)',
    bodyFontColor: 'rgba(102, 102, 102, 1)',
    cornerRadius: 2,
    xPadding: 10,
    yPadding: 12,
    caretSize: 10,
    callbacks: {
      title: () => null,
      label: (t) => t.yLabel.toLocaleString() + ' Lbs/Person',
      afterLabel: (t, d) => (typeof footnotes[d.datasets[t.datasetIndex].label] !== 'undefined' && typeof footnotes[d.datasets[t.datasetIndex].label][t.xLabel] === 'string')
        ? footnotes[d.datasets[t.datasetIndex].label][t.xLabel].replace(/\n\s+/g, '\n')
        : null
    }
  },
  hover: {
    mode: 'single'
  }
}

export const data = {
  labels: [
    '2000',
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018'
  ],
  datasets: [
    {
      label: 'Diverted',
      lineTension: 0.1,
      backgroundColor: 'rgba(0, 124, 146, 0.65)',
      borderWidth: 2,
      borderColor: 'rgba(0, 124, 146, 1.00)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(0, 124, 146, 1.00)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(0, 124, 146, 0.85)',
      pointHoverBorderColor: 'rgba(241, 240, 235, 1.00)',
      pointHoverBorderWidth: 2,
      pointRadius: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      pointHitRadius: 10,
      data: [877, 875, 870, 838, 1023, 914, 1058, 930, 1012, 1027, 956, 836, 962, 979, 957, 967, 900, 765, 815]
    },
    {
      label: 'Landfilled',
      lineTension: 0.1,
      backgroundColor: 'rgba(234, 171, 0, 0.3)',
      borderWidth: 2,
      borderColor: 'rgba(234, 171, 0, 1.00)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(234, 171, 0, 1.00)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 3, 5, 5, 5, 5, 5],
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(234, 171, 0, 0.85)',
      pointHoverBorderColor: 'rgba(241, 240, 235, 1.00)',
      pointHoverBorderWidth: 2,
      pointRadius: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 1, 1, 1, 1, 1],
      pointHitRadius: 10,
      data: [894, 790, 783, 723, 695, 656, 687, 622, 564, 565, 543, 522, 503, 544, 512, 513, 511, 455, 465]
    }
  ]
}

const footnotes = {
  Landfilled: {
    // First line should be blank, and
    // other lines should wrap here →|
    2013: `–––––––––––––––––––––––––––––––––
    In 2013, regulations changed for
    how mixed contruction waste was
    allowed to be sorted and recycled.
    Despite this, Stanford was still
    able to recycle 89% of its
    construction waste.`
  }
}
