export const type = 'bar'

export const options = {
  title: {
    display: false
  },
  hover: {
    mode: 'single'
  },
  tooltips: {
    enabled: true,
    mode: 'single',
    backgroundColor: 'rgba(252, 252, 252, 1)',
    bodyFontColor: 'rgba(102, 102, 102, 1)',
    displayColors: false,
    cornerRadius: 2,
    xPadding: 10,
    yPadding: 12,
    caretSize: 10,
    callbacks: {
      title: () => null,
      label: (t) => t.yLabel.toLocaleString() + ' %',
      afterLabel: (t, d) => (typeof footnotes[d.datasets[t.datasetIndex].label] !== 'undefined' && typeof footnotes[d.datasets[t.datasetIndex].label][t.xLabel] === 'string')
        ? footnotes[d.datasets[t.datasetIndex].label][t.xLabel].replace(/\n\s+/g, '\n')
        : null
    }
  },
  legend: {
    position: 'bottom',
    fullWidth: true,
    labels: {
      // boxWidth: 15,
      fontSize: 12,
      padding: 20
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Year'
      },
      ticks: {
        fontSize: 10
      },
      categoryPercentage: 0.65
      // barPercentage: 0.5
    }],
    yAxes: [{
      ticks: {
        max: 100,
        min: 0,
        stepSize: 10,
        // Format the Y-axis so there is a comma in the numbers
        callback: function (value, index, values) {
          if (parseInt(value) > 1000) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else {
            return value
          }
        }
      },
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Percentage of Commuters'
      }
    }]
  }

}

export const data = {
  labels: ['2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
  datasets: [{
    label: 'Sustainable Commuters',
    borderColor: 'rgba(0, 124, 146, 1)',
    backgroundColor: [
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)',
      'rgba(0, 124, 146, 1)'
    ],
    hoverBackgroundColor: 'rgba(0, 124, 146, 0.85)',
    data: [28, 28, 27, 31, 39, 42, 42, 47, 46, 48, 48, 51, 51, 50, 50, 50, 53]
  },
  {
    label: 'Drive-Alone Commuters',
    borderColor: 'rgba(116, 218, 217, 1)',
    backgroundColor: [
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)',
      'rgba(116, 218, 217, 1)'
    ],
    hoverBackgroundColor: 'rgba(116, 218, 217, 0.85)',
    data: [72, 72, 73, 69, 61, 58, 58, 53, 54, 52, 52, 49, 49, 50, 50, 50, 47]
  }]
}

const footnotes = {}
