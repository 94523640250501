export const type = 'line'

export const options = {
  height: 100,
  title: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      stacked: false,
      scaleLabel: {
        display: true,
        labelString: 'Energy Intensity (kBtu/GSF)'
      },
      ticks: {
        min: 0,
        max: 300,
        stepSize: 30,
        callback: value => value.toLocaleString()
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Year'
      },
      ticks: {
        fontSize: 10
      }
    }]
  },
  animation: {
    duration: 750
  },
  legend: {
    position: 'bottom',
    fullWidth: true,
    labels: {
      padding: 20
    }
  },
  tooltips: {
    enabled: true,
    mode: 'point',
    backgroundColor: 'rgba(252, 252, 252, 1)',
    bodyFontColor: 'rgba(102, 102, 102, 1)',
    cornerRadius: 2,
    xPadding: 10,
    yPadding: 12,
    caretSize: 10,
    callbacks: {
      title: () => null,
      label: t => t.yLabel.toLocaleString() + ' kBtu/GSF',
      afterLabel: (t, d) => (typeof footnotes[d.datasets[t.datasetIndex].label] !== 'undefined' && typeof footnotes[d.datasets[t.datasetIndex].label][t.xLabel] === 'string')
        ? footnotes[d.datasets[t.datasetIndex].label][t.xLabel].replace(/\n\s+/g, '\n')
        : null
    }
  },
  // Note: set this on the global variable
  hover: {
    mode: 'point'
  }
}

export const data = {
  labels: [
    '2000',
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018'
  ],
  datasets: [
    {
      label: 'Total Energy',
      lineTension: 0.1,
      backgroundColor: 'rgba(0, 124, 146, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(0, 124, 146, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(0, 124, 146, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: [5, 5, 5, 5, 5, 5, 5, 5, 5, 3, 5, 5, 5, 5, 5, 3, 3, 5, 5],
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(0, 124, 146, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: [1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 1, 1, 1, 1, 1, 5, 5, 1, 1],
      pointHitRadius: 10,
      data: [195, 198, 201, 208, 213, 209, 203, 200, 205, 197, 190, 183, 183, 184, 180, 149, 149, 149, 144]
    },
    {
      label: 'Electricity',
      lineTension: 0.1,
      backgroundColor: 'rgba(234, 172, 0, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(234, 172, 0, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(234, 172, 0, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(234, 172, 0, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [49, 47, 47, 48, 49, 49, 50, 50, 51, 50, 50, 49, 49, 50, 49, 47, 47, 45, 45]
    },
    {
      label: 'Heating',
      lineTension: 0.1,
      backgroundColor: 'rgba(140, 21, 21, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(140, 21, 21, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(140, 21, 21, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(140, 21, 21, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      pointHitRadius: 10,
      data: [86, 91, 92, 93, 93, 95, 91, 88, 90, 83, 82, 78, 76, 75, 69, 45, 45, 47, 47]
    },
    {
      label: 'Chilled Water',
      lineTension: 0.1,
      backgroundColor: 'rgba(116, 218, 217, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(116, 218, 217, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(116, 218, 217, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(116, 218, 217, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [60, 60, 62, 67, 72, 65, 62, 62, 64, 63, 57, 57, 57, 59, 62, 58, 57, 57, 52]
    }
  ]
}

const footnotes = {
  'Total Energy': {
    // First line should be blank, and
    // other lines should wrap here →|
    2009: `–––––––––––––––––––––––––––––––––
    The introduction of energy-saving
    programs targeting large- and
    small-scale retrofits, along with
    new construction standards,
    contributed to this decrease in
    energy use in 2009.`,
    2015: `–––––––––––––––––––––––––––––––––
    Efficiency increases in Stanford's
    energy system as a result of SESI
    allowed for a significant reduction
    in the amount of thermal energy
    produced in 2015.`,
    2016: `–––––––––––––––––––––––––––––––––
    Efficiency increases in Stanford's
    energy system as a result of SESI
    allowed for a significant reduction
    in the amount of thermal energy
    produced in 2016.`
  }
}
