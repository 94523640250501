import 'animate.css/animate.css'
import '@fortawesome/fontawesome-free/css/all.css'
import UIkit from 'uikit'
import { CountUp } from 'countup.js'
import Chart from 'chart.js'
import charts from '../charts/*.js'
import animations from './infographics'
import '/styles/style.scss'

const modalRefs = []
const chartRefs = {}

const closeAllModals = function () {
  setTimeout(function () {
    if (modalRefs.length) {
      const m = modalRefs.pop()
      if (m.isToggled()) {
        m.hide()
      } else {
        modalRefs.push(m)
      }
    }
  })
}

// Global scroll behavior defaults
UIkit.mixin({ data: { duration: 300 } }, 'scroll')

UIkit.util.ready(function () {
  //  Watch for sidebar scrolling and make sure modals are closed
  document
    .querySelectorAll('.sidebar, .interstitial')
    .forEach(el => {
      el.addEventListener('wheel', closeAllModals, { passive: true })
      el.addEventListener('touchstart', closeAllModals, { passive: true })
    })

  // Close modals on outside clicks
  UIkit.util.on('.uk-navbar, #totop', 'click', closeAllModals)

  // Toggle mobile menu on menu item click
  document
    .querySelectorAll('#mobile-nav .uk-nav a')
    .forEach(el => {
      UIkit.toggle(el, {
        target: '#mobile-nav'
      })
    })

  // Initialize Charts
  document
    .querySelectorAll('[data-chart]')
    .forEach(el => {
      if (el.dataset.chart) {
        chartRefs[el.dataset.chart] = new Chart(el, charts[el.dataset.chart])
      }
    })

  // Modal-related stuff
  document
    .querySelectorAll('[uk-modal]')
    .forEach(el => {
      // Register modals when they open
      UIkit.util.on(el, 'show', function () {
        modalRefs.push(UIkit.modal(el))
      })

      // Resize charts on modal open
      const chart = el.querySelector('[data-chart]')
      if (chart) {
        UIkit.util.on(el, 'show', function () {
          setTimeout(function () {
            chartRefs[chart.dataset.chart].resize()
          })
        })
      }
    })

  // Infographic animations
  document
    .querySelectorAll('.animated-infographic')
    .forEach(el => {
      UIkit.util.on(el, 'inview', () => {
        // Add animations if defined
        if (animations[el.id]) {
          for (const s in animations[el.id]) {
            el.querySelector(s)
              .classList.add('animated', ...animations[el.id][s])
          }
        }

        // Animate stat numbers with CountUp
        document
          .querySelectorAll('[id^=stat][id$=number]')
          .forEach(e => {
            if (!e.dataset.value) {
              e.dataset.value = Number(e.textContent.replace(/\D/g, ''))
            }
            const count = new CountUp(e.id, e.dataset.value)
            count.start()
          })
      })
      UIkit.util.on(el, 'outview', () => {
        // Remove animations if defined
        if (animations[el.id]) {
          for (const s in animations[el.id]) {
            el.querySelector(s)
              .classList.remove('animated', ...animations[el.id][s])
          }
        }

        // Animate stat numbers with CountUp
        document
          .querySelectorAll('[id^=stat][id$=number]')
          .forEach(e => {
            if (!e.dataset.value) {
              e.dataset.value = Number(e.textContent.replace(/\D/g, ''))
            }
            const count = new CountUp(e.id, e.dataset.value)
            count.start()
          })
      })
    })
})
