export const type = 'line'

export const options = {
  height: 100,
  title: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      stacked: false,
      scaleLabel: {
        display: true,
        labelString: 'Energy (kBtu)'
      },
      ticks: {
        min: 0,
        max: 2500000,
        stepSize: 250000,
        callback: value => Math.round(value / 1000).toLocaleString() + ' M'
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Year'
      },
      ticks: {
        fontSize: 10
      }
    }]
  },
  animation: {
    duration: 750
  },
  legend: {
    position: 'bottom',
    fullWidth: true,
    labels: {
      padding: 20
    }
  },
  tooltips: {
    enabled: true,
    backgroundColor: 'rgba(252, 252, 252, 1)',
    bodyFontColor: 'rgba(102, 102, 102, 1)',
    cornerRadius: 2,
    xPadding: 10,
    yPadding: 12,
    caretSize: 10,
    mode: 'point',
    callbacks: {
      title: () => null,
      label: t => t.yLabel.toLocaleString() + ' kBtu',
      afterLabel: (t, d) => (typeof footnotes[d.datasets[t.datasetIndex].label] !== 'undefined' && typeof footnotes[d.datasets[t.datasetIndex].label][t.xLabel] === 'string')
        ? footnotes[d.datasets[t.datasetIndex].label][t.xLabel].replace(/\n\s+/g, '\n')
        : null
    }
  },
  // Note: set this on the global variable
  hover: {
    mode: 'single'
  }
}

export const data = {
  labels: [
    '2000',
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018'
  ],
  datasets: [
    {
      label: 'Total Energy',
      lineTension: 0.1,
      backgroundColor: 'rgba(0, 124, 146, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(0, 124, 146, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(0, 124, 146, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: [5, 5, 5, 5, 5, 5, 5, 5, 5, 3, 5, 5, 5, 5, 5, 3, 3, 5, 5],
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(0, 124, 146, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: [1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 1, 1, 1, 1, 1, 5, 5, 1, 1],
      pointHitRadius: 10,
      data: [2127893, 2184978, 2226937, 2302133, 2405927, 2393714, 2351879, 2344566, 2409298, 2339099, 2349721, 2372176, 2354409, 2384045, 2372996, 1977528, 1989970, 2048396, 1998012]
    },
    {
      label: 'Electricity',
      lineTension: 0.1,
      backgroundColor: 'rgba(234, 172, 0, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(234, 171, 0, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(234, 171, 0, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(234, 171, 0, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [598478, 597370, 601648, 617004, 637377, 649275, 663593, 676094, 678755, 678534, 703694, 708842, 717435, 724504, 726727, 715639, 726067, 702254, 703466]
    },
    {
      label: 'Heating',
      lineTension: 0.1,
      backgroundColor: 'rgba(140, 21, 21, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(140, 21, 21, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(140, 21, 21, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(140, 21, 21, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [953703, 1012148, 1027408, 1033263, 1049315, 1079877, 1046025, 1024973, 1054905, 985839, 1012729, 1001716, 973062, 968650, 905724, 540363, 547521, 598034, 618458]
    },
    {
      label: 'Chilled Water',
      lineTension: 0.1,
      backgroundColor: 'rgba(116, 218, 217, 1)',
      fill: false,
      borderWidth: 2,
      borderColor: 'rgba(116, 218, 217, 1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(116, 218, 217, 1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(116, 218, 217, 1)',
      pointHoverBorderColor: '#F2F0EA',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [575712, 575460, 597880, 651866, 719234, 664561, 642261, 643499, 675638, 674726, 633298, 661619, 663912, 690891, 740544, 721527, 716382, 748108, 676089]
    }
  ]
}

const footnotes = {
  'Total Energy': {
    // First line should be blank, and
    // other lines should wrap here →|
    2009: `–––––––––––––––––––––––––––––––––
    The introduction of energy-saving
    programs targeting large- and
    small-scale retrofits, along with
    new construction standards,
    contributed to this decrease in
    energy use in 2009.`,
    2015: `–––––––––––––––––––––––––––––––––
    Efficiency increases in Stanford's
    energy system as a result of SESI
    allowed for a significant reduction
    in the amount of thermal energy
    produced in 2015.`,
    2016: `–––––––––––––––––––––––––––––––––
    Efficiency increases in Stanford's
    energy system as a result of SESI
    allowed for a significant reduction
    in the amount of thermal energy
    produced in 2016.`
  }
}
