// Infographic element animation classes (from animate.css)
// Override element timing and delays in styles/infographics/*.scss
export default {

  'infographic-academics': {
    '#academics_whiteboard': ['fadeIn'],
    '#academics_professor': ['slideInRight'],
    '#academics_chemist': ['slideInRight'],
    '#academics_graduate': ['slideInLeft']
  },

  'infographic-energy-supply': {
    '#energysupply_night_clouds_left_5': ['fadeOutRight'],

    '#energysupply_night_clouds_left_4': ['fadeOutRight'],
    '#energysupply_night_clouds_right_4': ['fadeOutLeft'],

    '#energysupply_night_clouds_left_3': ['fadeOutRight'],
    '#energysupply_night_clouds_right_3': ['fadeOutLeft'],

    '#energysupply_night_clouds_left_2': ['fadeOutRight'],
    '#energysupply_night_clouds_right_2': ['fadeOutLeft'],

    '#energysupply_night_clouds_left_1': ['fadeOutRight'],
    '#energysupply_night_clouds_right_1': ['fadeOutLeft'],

    '#energysupply_sun': ['fadeInUp'],
    '#energysupply_birds': ['fadeInRight'],
    '#energysupply_day_clouds_left': ['fadeInRight'],
    '#energysupply_day_clouds_right': ['fadeInLeft']
  },

  'infographic-central-office': {
    '#centraloffice_person_left': ['slideInLeft'],
    '#centraloffice_person_right': ['slideInRight'],
    '#centraloffice_person_seated': ['slideInLeft'],
    '#centraloffice_computer_metric_1': ['fadeIn'],
    '#centraloffice_computer_metric_2': ['fadeIn'],
    '#centraloffice_computer_metric_3': ['fadeIn'],
    '#centraloffice_computer_metric_line': ['fadeIn']
  },

  'infographic-buildings': {
    '#buildings_plumber': ['slideInRight'],
    '#buildings_electrician': ['slideInLeft'],
    '#buildings_gaffer': ['slideInUp']
  },

  'infographic-food-and-living-outside': {
    '#foodandliving_outside_person': ['slideInRight']
  },

  'infographic-food-and-living-inside': {
    '#foodandliving_inside_person': ['slideInRight']
  },

  'infographic-transportation': {
    '#transportation_bus': ['slideInRight'],
    '#transportation_van': ['slideInRight'],
    '#transportation_cyclist_left': ['slideInLeft'],
    '#transportation_cyclist_right': ['slideInLeft']
  }

}
